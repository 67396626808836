import React, {useEffect, useRef, useState} from 'react';
import {Router} from '$routes';
import './PromotionItem.scss';
import theme from '$styles/_theme.scss';
import ReactHtmlParser from 'react-html-parser';
import {throttle} from 'throttle-debounce';
import {useNavigate} from "react-router-dom";
import Slider from 'react-slick'
import {Icon} from "../../../core-components";

const PromotionItem = ({title, subTitle, promotionItemImageUrl1, promotionItemImageUrl2, promotionItemImageUrl3, buttonText, url, className}) => {
    const navigate = useNavigate()
    const promotionItemRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [style1, setStyle1] = useState({});
    const [style2, setStyle2] = useState({});
    const [style3, setStyle3] = useState({});

    function SampleNextArrow(props) {
        const {className, onClick} = props
        return (
            <div className={className}
                 style={{display: 'block', background: 'red'}}
                 onClick={onClick}>
                <Icon name="chevron_right-24px.svg" width="23px" height="23px"/>
            </div>
        )
    }

    function SamplePrevArrow(props) {
        const {className, onClick} = props
        return (
            <div className={className}
                 style={{display: 'block', background: 'green'}}
                 onClick={onClick}>
                <Icon name="chevron_left-24px.svg" width="23px" height="23px"/>
            </div>
        )
    }

  const settingsCatSlider = {
    dots: true,
    speed: 200,
    swipe: true,
    infinite: false,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
  }

    useEffect(() => {
        const onResize = throttle(250, () => setButtonSize());
        window.addEventListener('resize', onResize);
        setButtonSize(); // Initial button size calculation

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);



    useEffect(() => {
        if (promotionItemImageUrl1) {
            setStyle1({
                background: 'lightgrey url(' + promotionItemImageUrl1 + ') center / cover no-repeat'
            })
        }
    }, promotionItemImageUrl1);

    useEffect(() => {
        if (promotionItemImageUrl2) {
            setStyle2({
                background: 'lightgrey url(' + promotionItemImageUrl2 + ') center / cover no-repeat'
            })
        }
    }, promotionItemImageUrl2);

    useEffect(() => {
        if (promotionItemImageUrl3) {
            setStyle3({
                background: 'lightgrey url(' + promotionItemImageUrl3 + ') center / cover no-repeat'
            })
        }
    }, promotionItemImageUrl3);

    const setButtonSize = () => {
        if (!promotionItemRef.current) return;

        const button = promotionItemRef.current.querySelector('.button');
        const width = document.body.clientWidth;

        if (button) {
            button.style['max-width'] = window.matchMedia(`(max-width: ${theme.md})`).matches
                ? `${width - 40}px` // Subtracting 20px padding from both sides
                : '';
        }
    };

    const goTo = (url) => {
        if (!url) return;

        if (url.startsWith('http')) {
            window.location.href = url;
            window.scrollTo(0, 0);
        } else {
            navigate(url);
            window.scrollTo(0, 0);
        }
    };



    return (
        <div className='wrap-promotion'>
                <Slider {...settingsCatSlider}>
                    <div>
                        <div style={style1} className='promotion-father-wrap'>
                            <div className={`promotion-item ${className || ''}`}
                                 ref={promotionItemRef}>
                                <div className="main">
                                    <div className="title-area">
                                        <div className="title">{ReactHtmlParser(title)}</div>
                                        <div className="subtitle">{ReactHtmlParser(subTitle)}</div>
                                        {url && buttonText && buttonText.length > 0
                                            ? <div className="button button-blue truncate"
                                                   onClick={() => goTo(url)}>{buttonText}</div>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={style2} className='promotion-father-wrap'>
                            <div className={`promotion-item ${className || ''}`}
                                 ref={promotionItemRef}>
                                <div className="main">
                                    <div className="title-area">
                                        <div className="title">{ReactHtmlParser(title)}</div>
                                        <div className="subtitle">{ReactHtmlParser(subTitle)}</div>
                                        {url && buttonText && buttonText.length > 0
                                            ? <div className="button button-blue truncate"
                                                   onClick={() => goTo(url)}>{buttonText}</div>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={style3} className='promotion-father-wrap'>
                            <div className={`promotion-item ${className || ''}`}
                                 ref={promotionItemRef}>
                                <div className="main">
                                    <div className="title-area">
                                        <div className="title">{ReactHtmlParser(title)}</div>
                                        <div className="subtitle">{ReactHtmlParser(subTitle)}</div>
                                        {url && buttonText && buttonText.length > 0
                                            ? <div className="button button-blue truncate"
                                                   onClick={() => goTo(url)}>{buttonText}</div>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
        </div>
    );
};

export default PromotionItem;